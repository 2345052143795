<template>
  <div class="createWorkTalentDetail">
    <div class="createWorkTalentDetail-t">
      <img :src="detail.topicPicture" alt="" />
    </div>
    <div class="createWorkTalentDetail-b">
      <div class="name">{{ detail.topicTitle }}</div>
      <div class="remark">{{ detail.remark }}</div>
      <div class="intro"><div v-html="detail.topicContent"></div></div>
    </div>
  </div>
</template>

<script>
import {} from "./api.js";
import { gloabalCount } from "@/utils/common";
export default {
  name: "createWorkTalentDetail",
  data() {
    return {
      detail: {},
      bannerList: [],
      id: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getTalentDetailList();
    gloabalCount(this.id, 37, 1);
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.createWorkTalentDetail {
  .createWorkTalentDetail-t {
    height: 56.25vw;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .createWorkTalentDetail-b {
    position: relative;
    z-index: 2;
    background: #ffffff;
    border-radius: 16px 16px 0 0;
    margin-top: -10px;
    padding: 40px 58px 0;
    box-sizing: border-box;
    .name {
      font-size: 64px;
      font-weight: 600;
      color: #000000;
      line-height: 90px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 8px;
        height: 60px;
        background: #007eff;
        border-radius: 4px;
        left: -34px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .remark {
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
      margin: 20px 0 66px;
    }
    .intro {
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 34px;
      p,
      span,
      div {
        font-size: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 34px;
      }
    }
  }
}
</style>
<style lang="less">
.createWorkTalentDetail {
}
</style>
